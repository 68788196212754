import React, {FC, useState} from 'react';
import {ECardType} from "../../../typescript/enums";
//Components
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import {Button} from '@components/button';
import {EditIcon, PlusIcon} from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "@components/select";


//Api
import {useApiQuery} from "@hooks/api/useApiQuery";
import {apiGetAllCards} from "@api/cards";
import {TCard} from "@types";
import {string} from "zod";

interface IProps {
    cardId: string
    cardTitle: string
}

const ComboUpdateCard: FC<IProps> = ({cardId, cardTitle}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [id, setId] = useState<undefined | string>()

    const onSubmit = () => {
        console.log("Change CARD FROM", cardId, cardTitle, "to >> ", id)
    };

    const {data: allCards, handleRequest: handleGetAllCards} = useApiQuery({
        method: apiGetAllCards,
        isInitialRequest: true
    });

    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className={`gap-3 w-full`} variant="outline">
                    <EditIcon className={"flex-shrink-0"}/>Combo Update Card
                </Button>
            </DialogTrigger>

            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px]  min-h-[40vh]  overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >
                    <DialogHeader>
                        <DialogTitle>Add new task</DialogTitle>
                    </DialogHeader>


                    <div>
                        <Select
                            value={id}
                            onValueChange={(el) => setId(el)}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Card name"/>
                            </SelectTrigger>

                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>
                                        Select card
                                    </SelectLabel>
                                    {allCards && allCards
                                        .filter(
                                            (item) =>
                                                item.category ===
                                                ECardType.MARKET //Change me mb  <<<<<< ?
                                        )
                                        .map((item) => (
                                            <SelectItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.title?.uk} (
                                                {item.category})
                                            </SelectItem>
                                        ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>


                    <DialogFooter style={{marginTop: 'auto'}}>
                        <Button onClick={onSubmit}>Save</Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default ComboUpdateCard;
