import React, {FC, useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTrigger
} from '@components/dialog';
import {Button} from '@components/button';
import {EditIcon, TrashIcon} from 'lucide-react';
import {useFieldArray, useForm, useWatch} from 'react-hook-form';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@components/form';
import {Input} from '@components/input';

import {TCard, TDailyChest} from '@types';
import {ETaskType, EDailyChestType, ECardType} from '../../../typescript/enums';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from '@components/select';

interface IProps {

}

const defaultLoot = {
    currency: 'gton',
    amount: '0.1',
    chance: 0
};

const currencies = [
    {label: 'GTON (internal)', value: 'gton'},
    {label: 'TON', value: 'ton'},
    {label: 'ETH', value: 'eth'},
    {label: 'BTC', value: 'btc'}
];

const ComboLoot: FC<IProps> = ({}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [percentageError, setPercentageError] = useState(false);
    const form = useForm<TDailyChest>({
        mode: 'onChange',
        defaultValues: {
            loot: [defaultLoot],
            type: EDailyChestType.DAILY
        }
    });


    const loot = useWatch({name: 'loot', control: form.control});

    useEffect(() => {
        const fullChance = loot.reduce((a, item) => {
            return a + Number(item.chance);
        }, 0);

        if (fullChance !== 100) {
            setPercentageError(true);
        } else {
            setPercentageError(false);
        }
    }, [loot]);

    const {append, remove, fields} = useFieldArray({name: 'loot', control: form.control});


    const onSubmit = () => {

    };

    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className={`gap-3 edit w-full`} variant="ghost">
                    <EditIcon/>
                </Button>
            </DialogTrigger>
            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px] min-w-[80vw] min-h-[80vh]  max-h-[80vh] overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >

                    <Form {...form}>
                        <div className="flex flex-col gap-3">


                            <div className="flex flex-col gap-2">
                                Loot
                                {fields.map((item, i) => {
                                    return (
                                        <div className={`flex flex-col gap-2 pt-3 pb-3`}>
                                            <div
                                                key={item.id}
                                                className="flex gap-4 items-end pl-4"
                                            >
                                                <FormField
                                                    name={`loot.${i}.currency`}
                                                    render={({field}) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Currency</FormLabel>
                                                            <FormControl>
                                                                <Select
                                                                    value={field.value}
                                                                    onValueChange={field.onChange}
                                                                >
                                                                    <SelectTrigger>
                                                                        <SelectValue placeholder="Currency"/>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        <SelectGroup>
                                                                            {currencies.map(
                                                                                (item) => (
                                                                                    <SelectItem
                                                                                        key={
                                                                                            item.value
                                                                                        }
                                                                                        value={
                                                                                            item.value
                                                                                        }
                                                                                    >
                                                                                        {item.label}
                                                                                    </SelectItem>
                                                                                )
                                                                            )}
                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name={`loot.${i}.amount`}
                                                    render={({field, fieldState: {error}}) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Amount</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Price"
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name={`loot.${i}.chance`}
                                                    render={({field, fieldState: {error}}) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Chance</FormLabel>
                                                            <FormControl>
                                                                <div className="flex items-center gap-1 relative">
                                                                    <p className="absolute right-2">
                                                                        %
                                                                    </p>
                                                                    <Input
                                                                        {...field}
                                                                        onChange={(e) => {
                                                                            if (
                                                                                /^\d+(\.\d{1,2})?$/.test(
                                                                                    e.target.value
                                                                                )
                                                                            ) {
                                                                                const fullChance =
                                                                                    loot.reduce(
                                                                                        (
                                                                                            a,
                                                                                            item,
                                                                                            currentIndex
                                                                                        ) => {
                                                                                            if (
                                                                                                currentIndex !==
                                                                                                i
                                                                                            ) {
                                                                                                return (
                                                                                                    a +
                                                                                                    Number(
                                                                                                        item.chance
                                                                                                    )
                                                                                                );
                                                                                            } else {
                                                                                                return a;
                                                                                            }
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                if (
                                                                                    Number(
                                                                                        (
                                                                                            fullChance +
                                                                                            Number(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        ).toFixed(2)
                                                                                    ) <= 100 &&
                                                                                    Number(
                                                                                        e.target
                                                                                            .value
                                                                                    ) > 0
                                                                                ) {
                                                                                    field.onChange(
                                                                                        e
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                field.onChange(e);
                                                                            }
                                                                        }}
                                                                        className="pr-6"
                                                                    />
                                                                </div>
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <Button variant="ghost" onClick={() => remove(i)}>
                                                    <TrashIcon color="red"/>
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                                <Button
                                    className="mt-2 ml-4"
                                    onClick={() => {
                                        append(defaultLoot);
                                    }}
                                >
                                    Add loot
                                </Button>
                            </div>
                        </div>
                    </Form>


                    <DialogFooter className="flex items-center gap-4" style={{marginTop: 'auto'}}>
                        {percentageError && (
                            <p className="text-red-600">Percentage sum must be 100</p>
                        )}
                        <Button disabled={percentageError} onClick={onSubmit}>
                            Save
                        </Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default ComboLoot;
