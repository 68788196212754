import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ILoginRequest } from '../../typescript/requests';
import { apiLogin, apiRefreshToken } from '@api/auth';
import { ERoutes } from '../../typescript/enums';
import { useToast } from '@components/use-toast';
import axios from 'axios';

interface IUserContext {
    username?: string;
    email?: string;
    isLoggedIn: boolean;
    handleLogin: (data: ILoginRequest) => void
    handleLogout: () => void
}

export const UserContext = createContext<IUserContext>({
    isLoggedIn: false,
    handleLogin: () => {},
    handleLogout: () => {},
})

export const UserContextProvider = () => {
    const { toast } = useToast();
    const navigate = useNavigate()
    const location = useLocation()
    const [userData, setUserData] = useState({
        isLoggedIn: false,
    })

    const handleRefreshToken = () => {
        apiRefreshToken()
            .then(({data: {accessToken, refreshToken}}) => {
                setUserData({
                    isLoggedIn: true,
                })
                localStorage.setItem('access', accessToken)
                localStorage.setItem('refresh', refreshToken)
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
                navigate(ERoutes.HOME)
            })
            .catch(() => {
                setUserData({
                    isLoggedIn: false,
                })
                localStorage.clear()
                navigate(ERoutes.LOGIN)
                toast({
                    variant: 'destructive',
                    title: "Token expired"
                })
            })
    }

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (requestError) => {
            if (requestError?.response?.status === 401) {
                if (localStorage.getItem('refresh')) {
                    handleRefreshToken()
                }
            }
            return requestError;
        }
    );

    useEffect(() => {
        if (!localStorage.getItem("access") && !localStorage.getItem("refresh")) {
            localStorage.clear()
            navigate(ERoutes.LOGIN)
        } else if (!localStorage.getItem("access") && localStorage.getItem("refresh")) {
            handleRefreshToken()
        } else if (localStorage.getItem("access") && localStorage.getItem("refresh")) {
            setUserData({
                isLoggedIn: true,
            })
        }
    }, [location.pathname]);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (userData.isLoggedIn) {
            interval = setInterval(() => {
                handleRefreshToken()
            }, 900000)
        }

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [userData]);

    const handleLogin = (data: ILoginRequest) => {
        apiLogin(data)
            .then(({data: {user, tokens}}) => {
                setUserData({
                    isLoggedIn: true,
                    ...user
                })
                localStorage.setItem('access', tokens.accessToken)
                localStorage.setItem('refresh', tokens.refreshToken)
                axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
                navigate(ERoutes.HOME)
                toast({
                    title: "Login has been successfully",
                })
            })
            .catch(() => {
                setUserData({
                    isLoggedIn: false,
                })
                localStorage.clear()
                navigate(ERoutes.LOGIN)
                toast({
                    variant: 'destructive',
                    title: "Invalid Credentials"
                })
            })
    }

    const handleLogout = () => {
        localStorage.clear()
        navigate(ERoutes.LOGIN)
        toast({
            title: "Logout has been successfully",
        })
    }

    return <UserContext.Provider value={{
        ...userData,
        handleLogin,
        handleLogout,
    }}>
        <Outlet />
    </UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)
