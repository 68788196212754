import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function prettifyNumber(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
