import React from 'react';
import { Button } from '@components/button';
import { TrashIcon } from 'lucide-react';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@components/card';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiDeleteTask, apiGetTasks } from '@api/tasks';
import { useApiMutation } from '@hooks/api/useApiMutation';
import CreateTaskModal from '../components/modals/create_task';
import UpdateTaskModal from '../components/modals/update_task';
import DeleteCardModal from './components/cards/delete_card';

const telegramIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24"
        height="24"
        viewBox="0 0 48 48"
    >
        <circle cx="24" cy="24" r="21" fill="#74cccf"></circle>
        <path
            fill="none"
            stroke="#010101"
            stroke-miterlimit="10"
            d="M45.051,24c0,3.826-1.069,7.415-2.857,10.504 c-1.844,3.187-4.305,6.189-7.492,8.033c-3.089,1.787-6.877,2.871-10.702,2.871c-3.826,0-7.567-1.165-10.656-2.952 c-3.187-1.844-5.847-4.677-7.69-7.864C3.867,31.503,3.378,27.826,3.378,24c0-3.826,0.68-7.393,2.467-10.482 c1.844-3.187,4.366-6.038,7.553-7.882C16.487,3.849,20.174,3.188,24,3.188c3.826,0,7.371,0.906,10.46,2.694 c3.187,1.844,5.545,4.627,7.389,7.814C43.636,16.785,45.051,20.174,45.051,24z"
        ></path>
        <path
            fill="#d6e5e5"
            d="M17.689,26.814c0.492,1.906,1.089,3.785,1.785,5.626c0.151,0.399,0.366,0.85,0.782,0.946	c0.367,0.084,0.725-0.152,1.02-0.386c0.846-0.672,1.616-1.439,2.292-2.282c1.123,0.936,2.304,1.808,3.427,2.744	c0.437,0.364,0.884,0.734,1.414,0.94c0.53,0.205,1.168,0.22,1.635-0.104c0.321-0.222,0.525-0.574,0.692-0.927	c0.364-0.765,0.633-1.572,0.833-2.395c0.8-3.306,0.851-6.256,2.324-9.936c0.473-1.182,0.572-2.491,0.653-3.76	c0.048-0.748-0.541-1.378-1.289-1.408c-0.89-0.036-1.761,0.193-2.619,0.451c-6.127,1.842-11.582,4.246-17.015,6.668	c-0.505,0.225-1.044,0.413-1.436,0.803c-0.221,0.22-0.397,0.518-0.365,0.828c0.058,0.568,0.716,0.837,1.268,0.98	C14.627,26,16.133,26.517,17.689,26.814z"
        ></path>
        <polygon fill="#bcbcbc" points="20.843,28.309 20.539,33.213 23.569,30.717"></polygon>
        <path
            fill="none"
            stroke="#010101"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M20.721,28.01	c1.109,1.117,2.262,2.191,3.455,3.219"
        ></path>
        <polygon
            fill="#bcbcbc"
            points="18.264,26.388 29.64,18.955 30.146,19.41 21.197,27.652 20.792,28.916 20.135,33.163 17.758,27.197"
        ></polygon>
        <path
            fill="none"
            stroke="#010101"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M17.689,26.814	c0.492,1.906,1.089,3.785,1.785,5.626c0.151,0.399,0.366,0.85,0.782,0.946c0.367,0.084,0.725-0.152,1.02-0.386	c0.846-0.672,1.616-1.439,2.292-2.282c1.123,0.936,2.304,1.808,3.427,2.744c0.437,0.364,0.884,0.734,1.414,0.94	c0.53,0.205,1.168,0.22,1.635-0.104c0.321-0.222,0.525-0.574,0.692-0.927c0.364-0.765,0.633-1.572,0.833-2.395	c0.8-3.306,0.851-6.256,2.324-9.936c0.473-1.182,0.572-2.491,0.653-3.76c0.048-0.748-0.541-1.378-1.289-1.408	c-0.89-0.036-1.761,0.193-2.619,0.451c-6.127,1.842-11.582,4.246-17.015,6.668c-0.505,0.225-1.044,0.413-1.436,0.803	c-0.221,0.22-0.397,0.518-0.365,0.828c0.058,0.568,0.716,0.837,1.268,0.98C14.627,26,16.133,26.517,17.689,26.814z"
        ></path>
        <path
            fill="none"
            stroke="#010101"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M17.689,26.814	c3.357-2.222,6.437-4.187,9.794-6.409c0.695-0.46,2.562-1.753,2.87-1.262c0.411,0.654-6.383,5.935-9.624,8.879	c-0.164,1.727-0.287,3.459-0.37,5.192"
        ></path>
    </svg>
);

const twitterIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-twitter"
    >
        <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53A4.48 4.48 0 0 0 22.43 1c-.88.52-1.85.87-2.88 1.06A4.51 4.51 0 0 0 16.23 0c-2.49 0-4.5 2-4.5 4.48 0 .35.04.7.11 1.03C7.72 5.4 4.1 3.61 1.67 1.15A4.48 4.48 0 0 0 .89 3.62c0 1.56.8 2.93 2.02 3.74a4.47 4.47 0 0 1-2.03-.55v.06c0 2.18 1.55 4 3.63 4.41-.38.1-.79.15-1.2.15-.29 0-.57-.03-.84-.08a4.52 4.52 0 0 0 4.2 3.12A9.03 9.03 0 0 1 1 19.54a12.78 12.78 0 0 0 6.86 2.01c8.24 0 12.75-6.82 12.75-12.74 0-.19 0-.39-.01-.58A9.2 9.2 0 0 0 23 3z"></path>
    </svg>
);

const Tasks = () => {
    const { data: taskList, handleRequest: refetchTasks } = useApiQuery({
        method: apiGetTasks,
        isInitialRequest: true
    });

    const { handleRequest: handleDeleteTask } = useApiMutation({
        method: apiDeleteTask,
        onSuccess: () => refetchTasks()
    });

    return (
        <div className="flex flex-col gap-6">
            <CreateTaskModal onSuccess={refetchTasks} />
            {taskList && (
                <div className="flex gap-4 flex-wrap">
                    {taskList.map((item) => (
                        <Card
                            key={item.id}
                            className="w-[calc(50%-8px)] md:w-[calc(33%-20px)] flex flex-col justify-between"
                        >
                            <CardHeader>
                                <CardTitle>{item?.title?.uk}</CardTitle>
                                <CardDescription>{item?.description?.uk}</CardDescription>
                            </CardHeader>
                            <CardContent>
                                {item.type === 'TELEGRAM' && !item.image && telegramIcon}
                                {item.type === 'TWITTER' && !item.image && twitterIcon}

                                {item.image && (
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${item.image}`}
                                        alt={item?.title?.uk}
                                        className="mt-4"
                                    />
                                )}
                            </CardContent>
                            <CardFooter className="flex gap-2">
                                <UpdateTaskModal data={item} onSuccess={refetchTasks} />
                                <DeleteCardModal
                                    title="Delete current task"
                                    content="Are you sure you want to delete that task?"
                                    onSuccess={() => {
                                        handleDeleteTask(item.id);
                                        refetchTasks();
                                    }}
                                />
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Tasks;
