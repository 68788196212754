import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@components/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInValidation } from '../../validation';
import { ILoginRequest } from '../../typescript/requests';
import { Input } from '@components/input';
import { Button } from '@components/button';
import { useUserContext } from '@hooks/context/user_context';

const Login = () => {
    const {handleLogin} = useUserContext()
    const form = useForm<ILoginRequest>({
        mode: "onChange",
        resolver: zodResolver(signInValidation),
        defaultValues: {
            login: '',
            password: ''
        }
    });

    const onSubmit: SubmitHandler<ILoginRequest> = (data) => {
        handleLogin(data);
    };

    return (
        <div className="w-full flex items-center justify-center mt-12">
            <form
                className="border-2 p-4 w-96 flex flex-col justify-center items-center gap-2"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                Login
                <Form {...form}>
                    <FormField
                        control={form.control}
                        name="login"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Login</FormLabel>
                                <FormControl>
                                    <Input placeholder="Enter your login..." {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input placeholder="********" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button>Submit</Button>
                </Form>
            </form>
        </div>
    );
};

export default Login;
