import React, {useState} from 'react';
import {ECardType} from "../../typescript/enums";

//Icons
import {EditIcon} from 'lucide-react';

//Components
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "@components/select";
import {useApiQuery} from "@hooks/api/useApiQuery";
import {apiGetAllCards} from "@api/cards";
import ComboUpdateCard from "../components/modals/combo_update_card";
import ComboLoot from "../components/modals/combo_loot";

const Combo = () => {

    const cards = [
        {
            title: {
                en: "Card name yeah"
            },
            img: "e4769ed97b015e8b3ebff681b28f9b26.png",
            id: "25807371asgf-asgdh"
        },
        {
            title: {
                en: "Card name yeah"
            },
            img: "e4769ed97b015e8b3ebff681b28f9b26.png",
            id: "25807371asgf-asgdh"
        },
        {
            title: {
                en: "Card name yeah"
            },
            img: "e4769ed97b015e8b3ebff681b28f9b26.png",
            id: "25807371asgf-asgdh"
        },
    ]

    return (
        <div className="flex flex-col gap-8 w-full">
            <h2 className="font-bold text-2xl">Сombo</h2>

            <div className={"flex items-center justify-center gap-6"}>
                {cards.map((el, k) => ( //Cards
                    <div
                        key={`combo-${k}`}
                        className={"box-border p-4 w-[230px] h-fit rounded-xl border bg-card text-card-foreground  flex flex-col gap-4 justify-between"}
                    >
                        <div
                            className={"w-full flex items-center justify-center font-semibold leading-none tracking-tight"}>{el.title.en}</div>
                        <img
                            className="h-fit max-h-fit rounded-xl object-cover"
                            src={`${process.env.REACT_APP_IMAGE_URL}${el.img}`}
                            alt="Image Preview"
                        />

                        <div className={"w-full flex items-center justify-center"}>
                            <ComboUpdateCard cardId={el.id} cardTitle={el.title.en}/>
                        </div>
                    </div>
                ))}
            </div>

            <ComboLoot/>
        </div>
    );
};

export default Combo;
