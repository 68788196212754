import { useState } from 'react';
import { AxiosResponse } from 'axios';

interface IProps<T, R> {
    method: (props: T) => Promise<AxiosResponse<R>>;
    onSuccess?: (data: R) => void;
    onError?: (error: any) => void;
    onFinish?: () => void;
}

export const useApiMutation = <T, R>({method, onError, onSuccess, onFinish}: IProps<T, R>) => {
    const [response, setResponse] = useState<R | undefined>(undefined);
    const [error, setError] = useState<string>();
    const [isLoading, setLoading] = useState(false);

    const handleRequest = async (requestData: T) => {
        setLoading(true)
        setError(undefined)
        try {
            const {data} = await method(requestData)

            if (data) {
                setResponse(data)
                if (onSuccess) onSuccess(data)
            } else {
                new Error()
            }
        } catch (error: any) {
            setError(error.message)
            if (onError) onError(error)
        } finally {
            setLoading(false)
            if (onFinish) onFinish()
        }
    }

    return {
        data: response,
        error,
        isLoading,
        handleRequest,
    }
}
