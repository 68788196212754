export enum ERoutes {
    HOME = "/",
    LOGIN = "/login",
    CARDS = "/cards",
    DAILY = "/daily",
    TASKS = "/tasks",
    CHESTS = "/chests",
    COMBO = "/combo"
}

export enum ECardType {
    MARKET = "market",
    TEAM = "team",
    LEGAL = "legal",
    SPECIAL = "specials",
}

export enum ETaskType {
    LEVEL= 'level',
    REFERRALS = 'referrals',
    NEW_REFERRALS = 'new_referrals',
    CARD = 'card'
}

export enum ECreateTabsType {
    TRANSLATIONS = "translations",
    SETTINGS = "settings",
    LEVELS = "levels",
}

export enum EDailyType {
    TELEGRAM = 'TELEGRAM',
    TWITTER = 'TWITTER',
    TRUST = 'TRUST',
}

export enum EDailyChestType {
    DAILY = 1,
    DAILY_TASK = 2,
    TASK = 3
}
