import axios, { AxiosResponse } from 'axios';
import { ILoginRequest } from '../typescript/requests';
import { ILoginResponse, IRefreshResponse } from '../typescript/responses';

export const apiLogin = (data: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> => {
    return axios.post('/auth/local/sign-in', data);
}

export const apiRefreshToken = (): Promise<AxiosResponse<IRefreshResponse>> => {
    return axios.get('/auth/refresh', {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("refresh")}`,
        }
    });
}
